import { string } from "prop-types";

import classnames from "~utils/classnames";

import classes from "./Quote.module.scss";

const Quote = ({ children, author, className }) => (
  <section className={classnames(classes.quote, className)}>
    <div>{children}</div>
    {author && <div className={classes.quote_author}>- {author}</div>}
  </section>
);

export const QuoteType = {
  children: string,
  author: string,
  className: string,
};

Quote.propTypes = QuoteType;

export default Quote;
