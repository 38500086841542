import { arrayOf, oneOf, oneOfType, shape, string } from "prop-types";

import Button from "~components/Button";
import Card from "~components/Cards";
import { EventCardType } from "~components/Cards/EventCard";
import { LocationCardType } from "~components/Cards/LocationCard";
import DuoColorText from "~components/DuoColorText";
import ItemRow from "~components/ItemRow";
import { PreprDataType } from "~types";

import classes from "./CardCarousel.module.scss";

//This card is based on the location and event Section
const CardCarousel = ({
  title,
  titleEmphasis,
  subTitle,
  ctaLabel,
  ctaSlug,
  items,
  cardType,
  preprData,
}) => {
  return (
    <section className={classes.block}>
      {(title || subTitle) && (
        <div className={classes.titleWrapper}>
          {title && (
            <h2 className={classes.header_3}>
              <DuoColorText text={title} emphasis={titleEmphasis || ""} />
            </h2>
          )}
          {subTitle && <p className={classes.body}>{subTitle}</p>}
        </div>
      )}
      {ctaSlug && ctaLabel && (
        <Button
          variant="outline"
          href={`/${ctaSlug}`}
          className={classes.cta}
          size="small"
        >
          {ctaLabel}
        </Button>
      )}

      <div className={classes.items}>
        {items.length > 0 && (
          <ItemRow>
            {items.map((item) => (
              <Card
                key={item.id}
                {...item}
                type={cardType}
                preprData={preprData}
              />
            ))}
          </ItemRow>
        )}
      </div>
    </section>
  );
};

export const CardCarouselType = {
  id: string,
  title: string,
  titleEmphasis: string,
  subTitle: string,
  ctaLabel: string,
  ctaSlug: string,
  cardType: oneOf(["basic", "Event", "Location", "Venue", "link", "Author"]),
  items: arrayOf(oneOfType([shape(EventCardType), shape(LocationCardType)]))
    .isRequired,
  preprData: shape(PreprDataType),
};

CardCarousel.propTypes = CardCarouselType;

export default CardCarousel;
