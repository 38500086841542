import { useRouter } from "next/router";
import {
  arrayOf,
  node,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { memo } from "react";

import ArticleOverviewBlock, {
  ArticleOverviewBlockType,
} from "~components/ArticleOverviewBlock";
import CardCarousel, { CardCarouselType } from "~components/CardCarousel";
import FeaturedBlock, { FeaturedBlockType } from "~components/FeaturedBlock";
import IframeEmbed, { IframeEmbedType } from "~components/IframeEmbed";
import ImageCarousel, { ImageCarouselType } from "~components/ImageCarousel";
import ImageComponent from "~components/ImageComponent";
import LocationsAndEvents, {
  LocationsAndEventsType,
} from "~components/LocationsAndEvents";
import LogosBlock, { LogosBlockType } from "~components/LogosBlock";
import Quote, { QuoteType } from "~components/Quote";
import Partners from "~components/sections/Partners";
import TaggedArticleBlock, {
  TaggedArticleBlockType,
} from "~components/TaggedArticleBlock";
import TasksBlock, { TasksBlockType } from "~components/TasksBlock";
import TopTasksBlock, { TopTasksBlockType } from "~components/TopTasksBlock";
import TrustPilotBlock from "~components/TrustPilotBlock";
import USPBlock, { DynamicUSPBlockType } from "~components/USPBlock";
import YoutubeEmbedBlock, { YoutubeEmbedType } from "~components/YoutubeEmbed";
import Countdown from "~modules/CountdownBlock";
import DynamicEventsAndLocationsBlock, {
  DynamicEventsAndLocationsBlockType,
} from "~modules/DynamicEventsAndLocationsBlock";
import EventsAndLocationsGridBlock from "~modules/EventsAndLocationsGridBlock";
import ExpertCarousel from "~modules/ExpertCarousel";
import FaqBlock from "~modules/FaqBlock";
import ListWithImageBlock from "~modules/ListWithImageBlock";
import Neighbourhood, { NeighbourhoodTypes } from "~modules/NeighbourhoodBlock";
import PersonsBlock from "~modules/PersonsBlock";
import ProductGroupBlock, {
  ProductGroupBlockType,
} from "~modules/ProductGroupBlock";
import RecommendationsBlock, {
  RecommendationsBlockType,
} from "~modules/RecommendationsBlock";
import RegistrationForm, {
  RegistrationFormType,
} from "~modules/RegistrationForm";
import YoutubeCarousel from "~modules/YoutubeCarousel";
import classnames from "~utils/classnames";

import classes from "./DynamicContent.module.scss";

const ContentContainer = ({ children, style = {}, size = "xl" }) => (
  <div
    className={classnames(classes.container, classes[`container_${size}`])}
    style={style}
  >
    <div className={classes.grid}>
      <div className={classes.column}>{children}</div>
    </div>
  </div>
);

/**
 * This is the only instance where a divider should be visible when there is
 * personalized content. This Container is passed to the RecommendationsBlock
 * module so the divider is hidden when there is no personalized content.
 */
// eslint-disable-next-line react/prop-types
const RecommendationsContainer = ({ children }) => (
  <ContentContainer>
    {children}
    <div className={classes.divider} />
  </ContentContainer>
);

ContentContainer.propTypes = {
  children: node,
  style: object,
  size: oneOf(["xs", "sm", "md", "lg", "xl"]),
};

const DynamicContent = ({ content, parentPageId }) => {
  const { locale } = useRouter();

  if (!content || !Array.isArray(content) || content.length === 0) {
    return null;
  }

  return content
    .map((item) => {
      switch (item.type) {
        case "TopTasks":
          return (
            item.topTasks &&
            item.topTasks.length > 0 && (
              <ContentContainer key={item.id}>
                <TopTasksBlock {...item} />
                <div className={classes.divider} />
              </ContentContainer>
            )
          );
        case "ArticleOverviewBlock":
          return (
            item.articleItems &&
            item.articleItems.length > 0 && (
              <ContentContainer key={item.id}>
                <ArticleOverviewBlock locale={locale} {...item} />
                <div className={classes.divider} />
              </ContentContainer>
            )
          );
        case "TaggedArticleBlock": {
          const index = content
            .filter(({ type }) => type === "TaggedArticleBlock")
            .indexOf(item);
          let tags = item.tags || [];
          if (tags && typeof tags === "string") {
            // adding "tags" to components in prepr is not possible
            // for now we're using a string input field
            tags = tags.split(",").filter(Boolean);
          }
          return (
            <ContentContainer key={item.id}>
              <TaggedArticleBlock
                {...item}
                index={index}
                locale={locale}
                tags={tags}
                parentId={parentPageId}
              />
              <div className={classes.divider} />
            </ContentContainer>
          );
        }
        case "FeaturedBlock":
          return (
            item.featuredItems &&
            item.featuredItems.length > 0 && (
              <div key={item.id}>
                <ContentContainer size="md">
                  <FeaturedBlock {...item} />
                </ContentContainer>
                <ContentContainer>
                  <div className={classes.divider} />
                </ContentContainer>
              </div>
            )
          );
        case "UpcomingEventsBlock": {
          // Renamed from UpcomingEventsBlock to DynamicEventsAndLocationsBlock
          let tags = item.tags || [];
          if (tags && typeof tags === "string") {
            // adding "tags" to components in prepr is not possible
            // for now we're using a string input field
            tags = tags
              .split(",")
              .filter(Boolean)
              .map((tag) => tag.trim())
              .filter(Boolean);
          }
          return (
            <ContentContainer key={item.id}>
              <DynamicEventsAndLocationsBlock
                {...item}
                categories={item.categories || []}
                tags={tags}
              />
              <div className={classes.divider} />
            </ContentContainer>
          );
        }
        case "TasksBlock":
          return (
            item.lists &&
            item.lists.length > 0 && (
              <ContentContainer key={item.id}>
                <TasksBlock {...item} />
                <div className={classes.divider} />
              </ContentContainer>
            )
          );
        case "YouTube":
          return (
            <ContentContainer key={item.id} size="md" style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <YoutubeEmbedBlock embedId={item.embedId} title={item.title} />
                <div className={classes.divider} />
              </div>
            </ContentContainer>
          );
        case "AgendaBlock": {
          // Renamed from AgendaBlock to LocationsAndEvents
          return item.items && item.items.length > 0 ? (
            <ContentContainer key={item.id}>
              <LocationsAndEvents {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          ) : null;
        }
        case "ProductGroupBlock":
          return (
            item.productGroup &&
            item.products &&
            item.products.length > 0 && (
              <div key={`${item.id}-${locale}`}>
                <ProductGroupBlock {...item} />
                <div className={classes.divider} />
              </div>
            )
          );
        case "NeighbourhoodMap":
          return (
            <ContentContainer key={item.id}>
              <Neighbourhood {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "Iframe":
          return (
            <ContentContainer key={item.id} size="md" style={{ width: "100%" }}>
              {item.title && (
                <h2 className={classes.itemHeader}>{item.title}</h2>
              )}
              <IframeEmbed src={item.url} {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "ListWithImage":
          return (
            <ContentContainer key={item.id}>
              <ListWithImageBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "Partners":
          return (
            <ContentContainer size="md" key={item.id}>
              <Partners {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "Countdown":
          return (
            <ContentContainer key={item.id} size="lg">
              <Countdown {...item} />
            </ContentContainer>
          );
        case "FAQBlock":
          return (
            <ContentContainer key={item.id} size="md">
              <FaqBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "YoutubeCarousel":
          return (
            <ContentContainer key={item.id} size="lg">
              <YoutubeCarousel {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "PersonsBlock":
          return (
            <ContentContainer key={item.id}>
              <PersonsBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "TrustPilotBlock":
          return (
            <ContentContainer key={item.id}>
              <TrustPilotBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        case "RecommendationsBlock":
          return (
            <RecommendationsBlock
              key={item.id}
              personalization={item.personalization}
              Container={RecommendationsContainer}
            />
          );
        case "IconSlider":
          return (
            <>
              <ExpertCarousel {...item} />
              <div className={classes.spacing} />
            </>
          );
        case "RegistrationForm":
          return (
            <ContentContainer key={item.id} size="md">
              <RegistrationForm {...item} />
              <div className={classes.spacing} />
            </ContentContainer>
          );
        case "USPSBlock":
          return (
            <ContentContainer key={item.id}>
              <USPBlock
                {...item}
                data={item?.uspBlocks ? [item.uspBlocks[0]] : undefined}
              />
              <div className={classes.spacing} />
            </ContentContainer>
          );
        case "LogosBlock":
          return (
            <ContentContainer key={item.id}>
              <LogosBlock {...item} />
              <div className={classes.spacing} />
            </ContentContainer>
          );
        case "Assets": {
          const { images } = item;
          if (!images?.length) {
            return null;
          }
          const key = images.map(({ id }) => id).join("-");
          return (
            <ContentContainer key={key}>
              {images.length === 1 ? (
                <ImageComponent
                  key={images[0].id}
                  {...images[0]}
                  width={1800}
                  height={1020}
                />
              ) : (
                <ImageCarousel key={item.id} images={images} />
              )}
            </ContentContainer>
          );
        }
        case "Text": {
          const { body } = item;
          return (
            <ContentContainer key={item.id} size="md">
              <section>
                <div
                  className={classes.content}
                  dangerouslySetInnerHTML={{ __html: body }}
                />
              </section>
            </ContentContainer>
          );
        }
        case "IntroText": {
          const { content } = item;
          return (
            <ContentContainer key={item.id} size="md">
              <section>
                <p className={classes.intro}>{content}</p>
              </section>
            </ContentContainer>
          );
        }
        case "QuoteBlock": {
          const { content, author } = item;
          return (
            <ContentContainer key={item.id} size="md">
              <Quote author={author} className={classes.quote}>
                {content}
              </Quote>
            </ContentContainer>
          );
        }
        case "CardSlider": {
          const { title, items } = item;
          return (
            <ContentContainer key={item.id}>
              <CardCarousel cardType="link" title={title} items={items} />
            </ContentContainer>
          );
        }
        case "EventAndLocationGrid": {
          return (
            <ContentContainer key={item.id}>
              <EventsAndLocationsGridBlock {...item} />
              <div className={classes.divider} />
            </ContentContainer>
          );
        }
        default:
          return null;
      }
    })
    .filter(Boolean);
};

export const DynamicContentTypes = [
  shape(TopTasksBlockType),
  shape(ArticleOverviewBlockType),
  shape(FeaturedBlockType),
  shape(DynamicEventsAndLocationsBlockType),
  shape(TasksBlockType),
  shape(YoutubeEmbedType),
  shape(LocationsAndEventsType),
  shape(ProductGroupBlockType),
  shape(TaggedArticleBlockType),
  shape(IframeEmbedType),
  shape(RecommendationsBlockType),
  shape(RegistrationFormType),
  shape(DynamicUSPBlockType),
  shape(CardCarouselType),
  shape(QuoteType),
  shape(ImageCarouselType),
  shape(LogosBlockType),
  shape(NeighbourhoodTypes),
];

DynamicContent.propTypes = {
  content: arrayOf(oneOfType(DynamicContentTypes)),
  parentPageId: string,
};

DynamicContent.displayName = "DynamicContent";

export default memo(DynamicContent);
