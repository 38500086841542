import { bool, number, string } from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";

import classnames from "~utils/classnames";
import { clamp, debounce } from "~utils/utils";

import classes from "./IframeEmbed.module.scss";

const IframeEmbed = ({
  src,
  title,
  widthPct,
  widthPx,
  aspectRatio,
  heightPx,
  disableScrollbar,
}) => {
  const elRef = useRef(null);
  const iframeRef = useRef(null);
  const [height, setHeight] = useState(0);

  const style = useMemo(() => {
    // widthPx overrules widthPct
    // aspectRatio overrules heightPx
    const styleObj = {
      width: "100%",
      height: "100%",
    };
    if (widthPct) {
      styleObj.width = `${clamp(widthPct, 1, 100)}%`;
    }
    if (widthPx) {
      styleObj.width = `${widthPx}px`;
    }
    if (!aspectRatio && heightPx) {
      styleObj.height = `${heightPx}px`;
    }
    if (height) {
      styleObj.height = `${height}px`;
    }
    return styleObj;
  }, [height, widthPx, aspectRatio, heightPx, widthPct]);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (!elRef.current) {
        return;
      }
      if (aspectRatio) {
        setHeight(parseInt(elRef.current.offsetWidth * aspectRatio, 10));
      } else if (!heightPx) {
        // default ratio is 1/1
        setHeight(elRef.current.offsetWidth);
      }
      if (iframeRef.current) {
        iframeRef.current.setAttribute("width", elRef.current.offsetWidth);
        iframeRef.current.setAttribute("height", elRef.current.offsetHeight);
      }
    }, 100);
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [style, aspectRatio, heightPx]);

  return (
    <section className={classes.container}>
      <div ref={elRef} style={style}>
        <iframe
          ref={iframeRef}
          src={src}
          title={title}
          className={classnames(
            classes.iframe,
            disableScrollbar && classes.iframe__noScroll,
          )}
          scrolling={disableScrollbar ? "no" : "auto"}
        />
      </div>
    </section>
  );
};

export const IframeEmbedType = {
  src: string.isRequired,
  title: string,
  widthPx: number,
  aspectRatio: number,
  heightPx: number,
  widthPct: number,
  disableScrollbar: bool,
};

IframeEmbed.propTypes = IframeEmbedType;

export default IframeEmbed;
